.dsg-cell-error {
  background: #fff1f0;
}

.dsg-cell-was-changed {
  background: rgba(69, 128, 230, 0.1)
}

.dsg-input {
  font-size: 14px;
  font-weight: 400;
  color: rgba(26, 54, 64, 0.85);
}
